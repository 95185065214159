import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { injectIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import PageHeader from "../components/header/pageHeader";
import styles from "./styling/about.module.css";
import "./styling/about.module.css";
import Press from "../components/press/press";
import { FormattedMessage } from "gatsby-plugin-react-intl";

const Employee = (props) => (
  <div className={styles.employee}>
    <div className={styles.employeeWrapper}>
      <Img
        fluid={props.avatar.childImageSharp.fluid}
        className={styles.avatar}
        alt=""
      />
      <h2 className={styles.name}>{props.name}</h2>
    </div>
    <div className={styles.description}>
      <h2 className={styles.username}>{props.name}</h2>
      <p className={styles.excerpt}>
        <FormattedMessage id={props.excerpt} />
      </p>
    </div>
  </div>
);

const Paragraph = (props) => (
  <p className={styles.paragraph}>
    <FormattedMessage id={props.paragraph} />
  </p>
);

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayButton: false,
    };
    this.calcScroll = this.calcScroll.bind(this, 350);
  }

  //Scroll to top Functions
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), 17);
    this.setState({ intervalId: intervalId });
  }

  //mount and unmount functions
  componentDidMount() {
    window.addEventListener("scroll", this.calcScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.calcScroll);
  }

  // Scroll-trigger function
  calcScroll(h1) {
    var _window = window;
    var heightDiff = parseInt(h1);
    var scrollPos = _window.scrollY;
    if (scrollPos > heightDiff) {
      this.setState({
        displayButton: true,
      });
    } else {
      this.setState({
        displayButton: false,
      });
    }
  }

  render() {
    const path = this.props.location.pathname;
    const { CB, MM, PL, PW } = this.props.data;
    const { intl } = this.props;

    return (
      <Layout
        display="true"
        path={path}
        title={intl.formatMessage({ id: "about.title" })}
      >
        <PageHeader header="about.heading1" />
        <div className={styles.container}>
          <Paragraph paragraph="about.p1" />
          <Paragraph paragraph="about.p2" />
          <Paragraph paragraph="about.p3" />
          <Paragraph paragraph="about.p4" />
          <Paragraph paragraph="about.p5" />
        </div>
        <div className={styles.colorContainer}>
          <div className={styles.container}>
            <Employee
              name="Christian Brandhorst"
              avatar={CB}
              excerpt="about.CB"
            />
            <Employee name="Priya Linke" avatar={PL} excerpt="about.PL" />
            <Employee name="Marc Mogdanz" avatar={MM} excerpt="about.MM" />
            <Employee name="Philip Willms" avatar={PW} excerpt="about.PW" />
          </div>
        </div>
        {this.state.displayButton && (
          <button
            title="Back to top"
            className="scroll"
            onClick={() => {
              this.scrollToTop();
            }}
          />
        )}
        <div className={styles.pressContainer}>
          <div className={styles.container}>
            <Press data={this.props.data} />
          </div>
        </div>
        <div className="fillContainer" />
      </Layout>
    );
  }
}

export default injectIntl(About);

export const pageQuery = graphql`
  query {
    CB: file(relativePath: { eq: "about/CB.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1536) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    MM: file(relativePath: { eq: "about/MM.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1536) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PL: file(relativePath: { eq: "about/PL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1536) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PW: file(relativePath: { eq: "about/PW.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1536) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

import React, { Component, Fragment } from "react";
import "./press.css";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import format from "date-fns/format";
import pressData from "../../content/pressData.yaml";

class Press extends Component {
  constructor() {
    super();
    this.state = {
      displayMore: false,
    };
    this.showMore = this.showMore.bind(this);
  }

  showMore() {
    this.setState({
      displayMore: !this.state.displayMore,
    });
  }

  render() {
    const { displayMore } = this.state;
    const { intl } = this.props;
    const buttonLabel = displayMore ? "Show less..." : "Show more...";

    return (
      <div className="pressWrapper">
        <h2>
          <FormattedMessage id="press.heading" />
        </h2>
        <table className="table">
          <tbody>
            {pressData.map((project, index) => {
              if (!displayMore && index >= 5) {
                return;
              }

              let formattedDate;
              if (intl.locale == "de-DE") {
                formattedDate = format(new Date(project.date), "dd.MM.yyyy");
              } else {
                formattedDate = format(new Date(project.date), "MM/dd/yyyy");
              }

              return (
                <TableRow
                  key={project.link}
                  date={formattedDate}
                  text={project.text}
                  to={project.to}
                  link={project.link}
                />
              );
            })}
            <tr>
              <td className="buttonRow">
                <button onClick={this.showMore} className="loadMore">
                  {buttonLabel}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const TableRow = (props) => (
  <tr className="tableRow">
    <td className="date">{props.date}</td>
    <td className="linkText">{props.text}</td>
    <td className="link">
      <a target="_blank" href={props.to}>
        {props.link}
      </a>
    </td>
  </tr>
);

export default injectIntl(Press);
